const deg2rad = (deg) => deg * (Math.PI / 180);

const getDistanceBetweenPoints = (
  {
    firstPoint: {
      latitude: firstLat,
      longitude: firstLng,
    },
    secondPoint: {
      latitude: secondLat,
      longitude: secondLng,
    },
  },
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(secondLat - firstLat);
  const dLon = deg2rad(secondLng - firstLng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(firstLat)) * Math.cos(deg2rad(secondLat))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const findNearestPoint = (
  {
    targetPoint,
    points = [],
  },
) => {
  const min = { distance: null, point: null };

  for (let i = 0; i < points.length; i++) {
    const currentPoint = points[i];

    const distance = getDistanceBetweenPoints({ firstPoint: targetPoint, secondPoint: currentPoint });
    if (!min.distance || min.distance > distance) {
      min.distance = distance;
      min.point = currentPoint;
    }
  }

  return min.point;
};

export default {
  getDistanceBetweenPoints,
  findNearestPoint,
};
