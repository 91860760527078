import {
  createHandlers,
  createReducer,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  loadInProcess: true,
  outlets: [],
};

const handlers = createHandlers({
  [ActionsConstants.Outlets.OUTLETS_LOADING_SUCCESS]: (state, { outlets }) => ({
    ...state,
    outlets,
    loadInProcess: false,
  }),
  [ActionsConstants.Outlets.OUTLETS_LOADING_FAILED]: (state) => ({
    ...state,
    loadInProcess: true,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
