import { combineReducers } from 'redux';

import VariablesReducer from './variables';
import OutletsReducer from './outlets';
import CitiesReducer from './cities';

export default combineReducers({
  VariablesReducer,
  OutletsReducer,
  CitiesReducer,
});
