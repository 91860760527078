import React from 'react';

import Header from './components/common/Header';
import MapWrapper from './components/MapWrapper';

const Main = () => (
  <>
    <Header/>
    <MapWrapper/>
  </>
);

export default Main;
