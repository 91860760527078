import React from 'react';

import {
  GlobalConstants,
} from '../../../constants';

import './styles.scss';

import LogoIcon from './_media/LogoIcon.svg';

const Logo = () => (
  <img
    className='site-logo'
    src={LogoIcon}
    alt={GlobalConstants.Meta.SITE_NAME}
  />
);

export default Logo;
