import {
  createHandlers,
  createReducer,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  variablesLoadingInProcess: true,
  variables: {},
};

const handlers = createHandlers({
  [ActionsConstants.Variables.VARIABLES_LOADING_IN_PROCESS]: (state, { variablesLoadingInProcess }) => ({
    ...state,
    variablesLoadingInProcess,
  }),
  [ActionsConstants.Variables.VARIABLES_LOADING_SUCCESS]: (state, { variables }) => ({
    ...state,
    variablesLoadingInProcess: false,
    variables,
  }),
  [ActionsConstants.Variables.VARIABLES_LOADING_FAILED]: (state) => ({
    ...state,
    variablesLoadingInProcess: true,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
