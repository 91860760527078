import {
  createStore,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import Profile from '../utils/profile';

import allReducers from '../redux/reducers';

const ReduxStoreService = (() => {
  let instance = null;

  const init = () => {
    const store = Profile.isProduction() ? createStore(allReducers, applyMiddleware(thunk)) : createStore(allReducers, composeWithDevTools(applyMiddleware(thunk)));

    return {
      store,
    };
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export default ReduxStoreService;
