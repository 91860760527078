import {
  InfoWindow,
  Marker,
} from '@react-google-maps/api';
import React from 'react';

import MapMarkerInfo from '../MapMarkerInfo';
import MarkerIcon from './_media/MarkerIcon.svg';

import './styles.scss';

const MIN_SCALED_SIZE = 10;
const MAX_SCALED_SIZE = 60;

const VISIBILITY_TIMEOUT_MIN = 1990;
const VISIBILITY_TIMEOUT_MAX = 3000;

class MapMarker extends React.Component {
  constructor(props) {
    super(props);

    this.marker = undefined;
    this.currentScaledSize = MIN_SCALED_SIZE;
  }

  changeOpacity = () => {
    this.marker.setOpacity(1);
  };

  changeScaledSize = () => {
    this.currentScaledSize = MAX_SCALED_SIZE;
    this.marker.setIcon({
      url: MarkerIcon,
      scaledSize: new window.google.maps.Size(MAX_SCALED_SIZE, MAX_SCALED_SIZE),
    });
  };

  onLoadHandler = (marker) => {
    this.marker = marker;
    setTimeout(() => {
      this.changeOpacity();
      this.changeScaledSize();
    }, Math.random() * (VISIBILITY_TIMEOUT_MAX - VISIBILITY_TIMEOUT_MIN) + VISIBILITY_TIMEOUT_MIN);
  };

  render() {
    const {
      item: {
        latitude: lat,
        longitude: lng,
      },
      item,
      isOpened,

      onOpen,
      onClose,

      ...props
    } = this.props;

    return (
      <Marker
        opacity={0}
        onLoad={this.onLoadHandler}
        position={{ lat, lng }}
        onClick={onOpen}
        icon={{
          url: MarkerIcon,
          scaledSize: new window.google.maps.Size(this.currentScaledSize, this.currentScaledSize),
        }}
        {...props}
      >
        {
          (isOpened)
          && (
            <InfoWindow position={{ lat, lng }} onCloseClick={onClose}>
              <MapMarkerInfo item={item}/>
            </InfoWindow>
          )
        }
      </Marker>
    );
  }
}

export default MapMarker;
