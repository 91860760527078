import React from 'react';

import ViewPortContext from './ViewPortContext';

const MIN_WIDTH = 320;
const MOBILE_WIDTH = 1279;
const TABLET_WIDTH = 768;
const LAPTOP_WIDTH = 1025;
const DESKTOP_WIDTH = 1280;

class ViewportProvider extends React.Component {
  constructor(props) {
    super(props);

    const width = DESKTOP_WIDTH;
    const height = 0;
    const isMobile = width <= MOBILE_WIDTH;
    const isTablet = width >= TABLET_WIDTH;
    const isLaptop = width >= LAPTOP_WIDTH;
    const isAfterMin = width >= MIN_WIDTH;

    ViewPortContext.init({
      width,
      height,
      isMobile,
      isTablet,
      isLaptop,
      isAfterMin,
    });

    this.state = {
      width,
      height,
      isMobile,
      isTablet,
      isLaptop,
      isAfterMin,
    };
  }

  componentDidMount() {
    this.handleWindowResize();

    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth <= MOBILE_WIDTH,
      isTablet: window.innerWidth >= TABLET_WIDTH,
      isLaptop: window.innerWidth >= LAPTOP_WIDTH,
      isAfterMin: window.innerWidth >= MIN_WIDTH,
    });
  };

  render() {
    const {
      children,
    } = this.props;

    const {
      width,
      height,
      isMobile,
      isTablet,
      isLaptop,
      isAfterMin,
    } = this.state;

    const Context = ViewPortContext.get();

    return (
      <Context.Provider
        value={{
          width,
          height,
          isMobile,
          isTablet,
          isLaptop,
          isAfterMin,
        }}
      >
        <>
          {children}
        </>
      </Context.Provider>
    );
  }
}

export default ViewportProvider;
