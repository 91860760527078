import api from '../../../api';

import createAction from '../actionCreator';

import { ActionsConstants } from '../../../constants';

const loadingVariablesInProcessAction = (variablesLoadingInProcess) => createAction(
  ActionsConstants.Variables.VARIABLES_LOADING_IN_PROCESS,
  {
    variablesLoadingInProcess,
  },
);

const loadVariablesSuccessAction = (variables) => createAction(
  ActionsConstants.Variables.VARIABLES_LOADING_SUCCESS,
  {
    variables,
  },
);

const loadVariablesFailedAction = () => createAction(ActionsConstants.Variables.VARIABLES_LOADING_FAILED);

const loadVariablesFromApi = () => (dispatch) => {
  api.get('variables')
    .then(({ data: { data } }) => {
      dispatch(loadVariablesSuccessAction(data));
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error load vars from api', error);
      dispatch(loadVariablesFailedAction());
    });
};

export default {
  loadingVariablesInProcessAction,
  loadVariablesSuccessAction,
  loadVariablesFailedAction,

  loadVariablesFromApi,
};
