import React from 'react';

import {
  GlobalConstants,
} from '../../constants';

import MarkerIcon from './_media/MarkerIcon.svg';

import './styles.scss';

const Preload = () => (
  <div className='preload'>
    <div className='preload__text bounce-in-right'>
      <div className='preload__logo'>
        <img src={MarkerIcon} alt={GlobalConstants.Meta.SITE_NAME}/>
      </div>
    </div>
  </div>
);

export default Preload;
