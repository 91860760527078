import React from 'react';
import { connect } from 'react-redux';

import GeoLocationService from '../../services/GeoLocationService';
import LocationUtils from '../../utils/locationUtils';
import ViewPortContext from '../../context/viewport/ViewPortContext';

import Select from '../common/UI/Select';
import Map from '../Map';

import './styles.scss';

class MapWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    const { cities } = this.props;

    this.preparedCities = cities.map((city) => ({ label: city.name, id: city.id, ...city }));
    this.defaultCity = cities.find(({ isDefault }) => isDefault);
    this.selectUpdateSelectedIdFunction = () => {};

    this.state = {
      selectedCity: this.defaultCity,
    };
  }

  componentDidMount() {
    this.selectCityByGeoPosition();
  }

  selectCity = (city) => {
    this.setState({
      selectedCity: city,
    }, () => {
      this.selectUpdateSelectedIdFunction(city.id);
    });
  };

  updateSelectedCityByPosition = (position) => {
    const { outlets } = this.props;

    const nearestPoint = LocationUtils.findNearestPoint({
      targetPoint: position,
      points: outlets,
    });

    if (nearestPoint) {
      this.selectCity(nearestPoint.city);
    }
  };

  selectCityByGeoPosition = () => {
    try {
      navigator.geolocation.getCurrentPosition((
        {
          coords,
        },
      ) => {
        // eslint-disable-next-line no-console
        console.log('coords__', coords);
        this.updateSelectedCityByPosition(coords);
      }, (positionError) => {
        // eslint-disable-next-line no-console
        console.error('Error get current position', positionError);
        this.selectCityByIp();
      }, {
        maximumAge: 10 * 60 * 1000,
        timeout: 4 * 1000,
      });
    } catch (e) {
      this.selectCityByIp();
    }
  };

  selectCityByIp = () => {
    GeoLocationService.getInstance().loadCurrentInfo()
      .then((position) => {
        this.updateSelectedCityByPosition(position);
      })
      .catch(() => {

      });
  };

  onSelectCityHandler = (city) => {
    this.setState({
      selectedCity: city,
    });
  };

  setSelectedIdRegistrar = (setSelectedIdFunction) => {
    this.selectUpdateSelectedIdFunction = setSelectedIdFunction;
  };

  render() {
    const Context = ViewPortContext.get();

    const { selectedCity } = this.state;

    return (
      <div
        ref={this.mapRef}
        className='main-map'
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Context.Consumer>
          {(
            {
              isMobile,
            },
          ) => (
            <Map
              containerRef={this.mapRef}
              selectedCity={selectedCity}
              isMobile={isMobile}
            />
          )}
        </Context.Consumer>
        <Select
          options={this.preparedCities}
          placeholder='Выбрать город'
          onSelect={this.onSelectCityHandler}
          defaultSelectedId={this.defaultCity ? this.defaultCity.id : undefined}
          setSelectedIdRegistrar={this.setSelectedIdRegistrar}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cities: state.CitiesReducer.cities,
    outlets: state.OutletsReducer.outlets,
  }),
)(MapWrapper);
