import React from 'react';

import './styles.scss';

const MapMarkerInfo = (
  {
    item: {
      city: {
        name: cityName,
      },
      emporium,
      address,
    },
  },
) => (
  <div className='info-window-content'>
    <div className='info-window-content__header'>{cityName}{emporium ? `, ${emporium}` : ''}</div>
    <div className='info-window-content__address'>{address}</div>
  </div>
);

export default MapMarkerInfo;
