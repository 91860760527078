import {
  GoogleMap,
  LoadScript,
} from '@react-google-maps/api';
import React from 'react';
import { connect } from 'react-redux';

import RefService, { RefSection } from '../../services/RefService';

import MapMarker from './component/MapMarker';

import './styles.scss';

const containerStyle = {
  width: 'calc(100% + 10px)',
  height: 'calc(100% + 10px)',
  margin: '-5px',
};

const USE_SMOOTH_ZOOM = false;
const MIN_ZOOM = 4;
const MAX_ZOOM = 20;
const MAX_ZOOM_TO_POINT = 15;

const darkThemeStyle = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];

class Map extends React.Component {
  constructor(props) {
    super(props);

    const { outlets } = props;

    this.map = null;

    const outlet = outlets[0];
    this.mapCenter = { lat: outlet.latitude, lng: outlet.longitude };

    this.afterMapInitAction = [];

    this.state = {
      openedMarkerId: null,
    };
  }

  componentDidUpdate({ selectedCity: prevSelectedCity }) {
    const { selectedCity } = this.props;

    if (selectedCity !== prevSelectedCity) {
      if (!this.map) {
        this.afterMapInitAction.push(this.fitToSelectedCityPoints);
      } else {
        this.fitToSelectedCityPoints();
      }
    }
  }

  fitToSelectedCityPoints = () => {
    const { selectedCity, outlets } = this.props;

    if (!selectedCity) {
      return;
    }

    const fitToSelectedCityPoints = () => {
      this.mapFitBounds({
        points: outlets.filter(({ city: { id: cityId } }) => cityId === selectedCity.id),
      });
    };

    this.setState({
      openedMarkerId: null,
    }, () => {
      if (USE_SMOOTH_ZOOM) {
        this.mapFitBounds({
          useCurrentCenter: true,
          onEnd: fitToSelectedCityPoints,
        });
      } else {
        fitToSelectedCityPoints();
      }
    });
  };

  getBoundsZoomLevel = (bounds) => {
    const {
      containerRef: {
        current: {
          clientWidth: mapDimWidth,
          clientHeight: mapDimHeight,
        } = {},
      } = {},
    } = this.props;

    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    function latRad(lat) {
      const sin = Math.sin(lat * Math.PI / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDimHeight, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDimWidth, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  panCenter = () => {
    const { isMobile } = this.props;
    const headerDecorationRef = RefService.getInstance().get(RefSection.HEADER_DECORATION);

    if (isMobile && headerDecorationRef && headerDecorationRef.current) {
      this.map.panBy(0, headerDecorationRef.current.clientHeight / 3.8 * -1);
    }
  };

  navigateToPoint = (
    {
      center,
      zoomLevel,
      onEnd,
      panCenter = false,
    } = {},
  ) => {
    const currentZoom = this.map.getZoom();

    if (USE_SMOOTH_ZOOM) {
      if (currentZoom === zoomLevel) {
        if (onEnd) {
          onEnd();
        }
        return;
      }

      const intermediateZoomLevel = currentZoom + Math.min(3, Math.abs(currentZoom - zoomLevel)) * (currentZoom > zoomLevel ? -1 : 1);

      window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.navigateToPoint({ center, zoomLevel, onEnd });
      });

      this.map.setZoom(intermediateZoomLevel);
      if (center) {
        this.map.panTo(center);
        if (panCenter) {
          this.panCenter();
        }
        // this.map.setCenter(center);
      }
    } else {
      this.map.setZoom(zoomLevel);
      if (center) {
        this.map.panTo(center);
        if (panCenter) {
          this.panCenter();
        }
      }

      onEnd();
    }
  };

  mapFitBounds = (
    {
      points,
      useCurrentCenter = false,
      onEnd = () => {},
    } = {},
  ) => {
    const { outlets } = this.props;

    const bounds = new window.google.maps.LatLngBounds();

    (points || outlets).forEach(({ latitude, longitude }) => {
      bounds.extend(new window.google.maps.LatLng(latitude, longitude));
    });

    const newZoomLevel = this.getBoundsZoomLevel(bounds);

    this.navigateToPoint({
      center: useCurrentCenter ? this.map.getCenter() : bounds.getCenter(),
      zoomLevel: Math.min(newZoomLevel, MAX_ZOOM_TO_POINT),
      onEnd,
    });
  };

  onLoadMapHandler = (map) => {
    const { selectedCity } = this.props;

    this.map = map;

    if (this.afterMapInitAction.length > 0) {
      this.afterMapInitAction.forEach((action) => action());
    } else if (selectedCity) {
      this.fitToSelectedCityPoints();
    } else {
      this.mapFitBounds();
    }
  };

  toggleMarkerInfo = (markerId) => {
    const { openedMarkerId } = this.state;
    const { outlets } = this.props;

    const markerIsActive = openedMarkerId && markerId === openedMarkerId;
    const newOpenedMarkerValue = markerIsActive ? null : markerId;

    if (!newOpenedMarkerValue) {
      this.setState({
        openedMarkerId: null,
      });
    } else {
      const activePoint = outlets.find(({ id }) => id === markerId);

      if (activePoint && !markerIsActive) {
        const currentZoom = this.map.getZoom();
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(activePoint.latitude, activePoint.longitude));

        if (Math.abs(currentZoom - MAX_ZOOM_TO_POINT) >= 2) {
          this.navigateToPoint({
            center: bounds.getCenter(),
            zoomLevel: MAX_ZOOM_TO_POINT,
            panCenter: true,
            onEnd: () => {
              this.setState({
                openedMarkerId: newOpenedMarkerValue,
              });
            },
          });
        } else {
          this.setState({
            openedMarkerId: newOpenedMarkerValue,
          }, () => {
            this.map.panTo(bounds.getCenter());
            this.panCenter();
          });
        }
      }
    }
  };

  render() {
    const {
      variables: {
        // GOOGLE_MAPS_MAP_ID,
        GOOGLE_MAPS_API_KEY,
      },
      outlets = [],
    } = this.props;
    const {
      openedMarkerId,
    } = this.state;

    if (!this.mapCenter) {
      return null;
    }

    return (
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          onLoad={this.onLoadMapHandler}
          onCenterChanged={() => {
            if (this.map) {
              const currentCenter = this.map.getCenter();
              this.mapCenter = {
                lat: currentCenter.lat(),
                lng: currentCenter.lng(),
              };
            }
          }}
          options={{
            isFractionalZoomEnabled: true,
            // mapId: GOOGLE_MAPS_MAP_ID,
            maxZoom: MAX_ZOOM,
            // minZoom: 4,
            keyboardShortcuts: false,
            disableDefaultUI: true,
            restriction: {
              strictBounds: true,
              latLngBounds: {
                east: 179,
                west: -179,
                north: 85,
                south: -85,
              },
            },
            styles: darkThemeStyle,
          }}
          center={this.mapCenter}
          zoom={MIN_ZOOM}
          mapContainerStyle={containerStyle}
          onClick={() => this.toggleMarkerInfo(null)}
        >
          {
            outlets.map((item) => (
              <MapMarker
                key={`${item.id}`}
                item={item}
                isOpened={openedMarkerId === item.id}
                onOpen={() => this.toggleMarkerInfo(item.id)}
                onClose={() => this.toggleMarkerInfo(null)}
              />
            ))
          }
        </GoogleMap>
      </LoadScript>
    );
  }
}

export default connect(
  (state) => ({
    variables: state.VariablesReducer.variables,
    outlets: state.OutletsReducer.outlets,
  }),
)(Map);
