import api from '../../../api';

import createAction from '../actionCreator';

import { ActionsConstants } from '../../../constants';

const loadOutletsSuccessAction = (outlets) => createAction(
  ActionsConstants.Outlets.OUTLETS_LOADING_SUCCESS,
  { outlets },
);

const loadOutletsFailedAction = () => createAction(
  ActionsConstants.Outlets.OUTLETS_LOADING_FAILED,
  {},
);

const loadOutletsFromApi = () => (dispatch) => {
  api.get('outlets')
    .then(({ data: { data } }) => {
      dispatch(loadOutletsSuccessAction(data));
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error load outlets', error);
      dispatch(loadOutletsFailedAction());
    });
};

export default {
  loadOutletsFromApi,

  loadOutletsSuccessAction,
  loadOutletsFailedAction,
};
