const RefSection = {
  HEADER_DECORATION: 'HEADER_DECORATION',
};

const RefService = (() => {
  let instance = null;

  const refs = {};

  const init = () => ({
    get: (refSection) => refs[refSection],
    register: (refSection, ref) => {
      refs[refSection] = ref;
    },
  });

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export {
  RefSection,
};
export default RefService;
