import React, { useState, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import Button from '../Button';

import './styles.scss';

const Item = (
  {
    text,
    selected,
    onClick,
  },
) => (
  <Button
    className={`select__option ${selected ? 'select__option_selected' : ''}`}
    onClick={onClick}
  >
    {text}
  </Button>
);

const Select = (
  {
    defaultSelectedId,
    placeholder,
    onSelect,
    options = [],
    setSelectedIdRegistrar,
  },
) => {
  if (options.length === 0) {
    return <></>;
  }

  const [selectedId, setSelectedId] = useState(defaultSelectedId);
  const [isOpened, setIsOpened] = useState(false);
  const selectRef = useRef(null);

  if (setSelectedIdRegistrar) {
    setSelectedIdRegistrar(setSelectedId);
  }

  const {
    label: selectedOptionLabel = placeholder,
  } = options.find(({ id }) => id === selectedId) || {};

  const toggleIsOpened = (isOpenedValue = !isOpened) => {
    setIsOpened(isOpenedValue);
  };

  const checkClickTarget = (e) => {
    if (!!selectRef.current && !selectRef.current.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();

      toggleIsOpened(false);

      document.body.removeEventListener('click', checkClickTarget, false);
    }
  };

  const onToggleList = () => {
    toggleIsOpened();

    if (!isOpened) {
      document.body.addEventListener('click', checkClickTarget, false);
    } else {
      document.body.removeEventListener('click', checkClickTarget, false);
    }
  };

  return (
    <div
      ref={selectRef}
      className={`select ${isOpened ? 'select_opened' : ''}`}
    >
      <SwitchTransition>
        <CSSTransition
          key={`select-animation-${isOpened ? 'opened' : 'closed'}`}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames='select-animation'
        >
          <>
            {
              (isOpened)
              && (
                <div
                  className='select__content'
                >
                  <Scrollbars
                    style={{
                      width: '100%',
                    }}
                    autoHeight
                    autoHeightMax={`calc(1rem * ${83 * 9} / var(--font-size__desktop-int))`}
                    renderThumbVertical={() => (
                      <div className='scrollbar__thumb'/>
                    )}
                  >
                    {
                      options.map((item) => {
                        const { id, label } = item;

                        return (
                          <Item
                            key={id}
                            text={label}
                            selected={selectedId === id}
                            onClick={() => {
                              setIsOpened(false);
                              setSelectedId(id);
                              if (onSelect) {
                                onSelect(item);
                              }
                            }}
                          />
                        );
                      })
                    }
                  </Scrollbars>
                </div>
              )
            }
          </>
        </CSSTransition>
      </SwitchTransition>
      <Button
        className='select__value'
        onClick={onToggleList}
      >
        <div className='select__arrow'>
          <div className='select__icon'/>
        </div>
        <div className='select__selected'>{selectedOptionLabel}</div>
      </Button>
    </div>
  );
};

export default Select;
