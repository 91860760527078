import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  CitiesActions,
  OutletsActions,
  VariablesActions,
} from './js/redux/actions';

import GaPusher from './js/utils/gaPusher';
import YmPusher from './js/utils/ymPusher';

import Main from './js/Main';
import Preload from './js/components/Preload';

import 'normalize.css';
import './assets/styles/fonts.scss';
import './assets/styles/index.scss';

const App = (
  {
    variables: {
      WEBSITE_IS_OPENED,
    },
    variablesLoadingInProcess,
    outletsLoadInProcess,
    citiesLoadInProcess,

    loadVariablesFromApi,
    loadOutletsFromApi,
    loadCitiesFromApi,
  },
) => {
  const [shownPreload, setShownPreload] = useState(true);

  useEffect(() => {
    loadVariablesFromApi();
    loadOutletsFromApi();
    loadCitiesFromApi();

    setTimeout(() => {
      setShownPreload(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!variablesLoadingInProcess) {
      GaPusher.getInstance().initializeGa();
      YmPusher.getInstance().pushCurrentLocation();
    }
  }, [variablesLoadingInProcess]);

  if (variablesLoadingInProcess
    || !WEBSITE_IS_OPENED
    || outletsLoadInProcess
    || citiesLoadInProcess) {
    return <></>;
  }

  return (
    <>
      {YmPusher.getInstance().initialize()}
      <Main/>
      {
        (shownPreload) && (
          <Preload/>
        )
      }
    </>
  );
};

export default connect(
  (state) => ({
    variables: state.VariablesReducer.variables,
    variablesLoadingInProcess: state.VariablesReducer.variablesLoadingInProcess,

    outletsLoadInProcess: state.OutletsReducer.loadInProcess,
    citiesLoadInProcess: state.CitiesReducer.loadInProcess,
  }),
  (dispatch) => ({
    loadVariablesFromApi: () => {
      dispatch(VariablesActions.loadVariablesFromApi());
    },
    loadOutletsFromApi: () => {
      dispatch(OutletsActions.loadOutletsFromApi());
    },
    loadCitiesFromApi: () => {
      dispatch(CitiesActions.loadCitiesFromApi());
    },
  }),
)(App);
