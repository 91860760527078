import React from 'react';

import './styles.scss';

const Button = (
  {
    type,
    children,
    ...props
  },
) => (
  <button
    className={`${type}`}
    type='button'
    {...props}
  >
    {children}
  </button>
);

export default Button;
