import {
  createHandlers,
  createReducer,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  loadInProcess: true,
  cities: [],
};

const handlers = createHandlers({
  [ActionsConstants.Cities.CITIES_LOAD_SUCCESS]: (state, { cities }) => ({
    ...state,
    cities,
    loadInProcess: false,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
