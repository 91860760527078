import baseAxios from 'axios';

import ReduxStoreService from '../services/ReduxStoreService';
import { extractErrorDataFromResponseApi } from '../utils/apiUtils';

import { ActionsConstants } from '../constants';

const api = baseAxios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { error: errorMessage } = extractErrorDataFromResponseApi(error);
    if (['API_ERROR', 'INTERNAL_ERROR'].includes(errorMessage)) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      ReduxStoreService.getInstance().store.dispatch({
        type: ActionsConstants.Auth.SIGN_OUT,
      });
      return new Promise(() => {});
    }

    return Promise.reject(error);
  },
);

export default api;
