import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'element-closest/browser';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import ReduxStoreService from './js/services/ReduxStoreService';
import ViewportProvider from './js/context/viewport/ViewPortProvider';

import App from './App';

ReactDOM.render(
  <Provider store={ReduxStoreService.getInstance().store}>
    <ViewportProvider>
      <App/>
    </ViewportProvider>
  </Provider>,
  document.getElementById('root'),
);
