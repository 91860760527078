import api from '../../../api';

import createAction from '../actionCreator';

import { ActionsConstants } from '../../../constants';

const loadCitiesFromApiSuccessAction = (cities) => createAction(
  ActionsConstants.Cities.CITIES_LOAD_SUCCESS,
  { cities },
);

const loadCitiesFromApi = () => (dispatch) => {
  api.get('cities')
    .then(({ data: { data } }) => {
      dispatch(loadCitiesFromApiSuccessAction(data));
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export default {
  loadCitiesFromApi,

  loadCitiesFromApiSuccessAction,
};
