const extractErrorDataFromResponseApi = (error) => {
  const { response: { data = { error: 'API_ERROR' } } = {} } = error;
  return data;
};

const handleError = (error, handlers, defaultHandler = () => {}, defaultWithoutApiErrors = ['INTERNAL_ERROR']) => {
  const handler = handlers[error]
    || (!defaultWithoutApiErrors.includes(error) ? handlers.DEFAULT || defaultHandler : () => {});
  try {
    if (handler) handler();
  } catch (e) {
    if (!defaultWithoutApiErrors.includes(error)) {
      defaultHandler();
    }
  }
};

export {
  handleError,
  extractErrorDataFromResponseApi,
};
