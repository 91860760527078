const Profile = (() => {
  const profile = process.env.REACT_APP_PROFILE;

  return {
    isLocal: () => profile === 'local',
    isTest: () => profile === 'test',
    isProduction: () => profile === 'production',
  };
})();

export default Profile;
