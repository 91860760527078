import React from 'react';

const ViewPortContext = (() => {
  let context = null;

  const init = (
    {
      width,
      height,
      isMobile,
      isTablet,
      isLaptop,
    },
  ) => React.createContext({
    width,
    height,
    isMobile,
    isTablet,
    isLaptop,
  });

  return {
    get: () => context,
    init: (options) => {
      context = init(options);
    },
  };
})();

export default ViewPortContext;
