import React from 'react';
import YM, { YMInitializer } from 'react-yandex-metrika';

import ReduxStoreService from '../services/ReduxStoreService';

const YmPusher = (() => {
  let instance = null;
  let isInitialize = false;

  const init = () => {
    const initialize = () => {
      if (isInitialize) {
        return <></>;
      }

      const {
        VariablesReducer: {
          variables: {
            YANDEX_METRIKA_KEY,
          } = {},
        } = {},
      } = ReduxStoreService.getInstance().store.getState();

      if (YANDEX_METRIKA_KEY) {
        isInitialize = true;
        // eslint-disable-next-line no-console
        console.log('YM init...');

        return (
          <YMInitializer
            accounts={[parseInt(YANDEX_METRIKA_KEY, 10)]}
            options={
              {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
              }
            }
          />
        );
      }

      return (
        <>
        </>
      );
    };

    const pushCurrentLocation = () => {
      if (!isInitialize) {
        return;
      }

      YM('hit', window.location.pathname + window.location.search);
    };

    const event = (name, params) => {
      if (!isInitialize) {
        return;
      }
      YM('reachGoal', name, params);
    };

    const clickEvent = (action, label) => {
      event(action, { label });
    };

    const submitEvent = (action, label) => {
      event(action, { label });
    };

    const openPopupEvent = (label) => {
      event('OpenPopup', { label });
    };

    const closePopupEvent = (label) => {
      event('ClosePopup', { label });
    };

    return {
      initialize,
      pushCurrentLocation,
      event,
      clickEvent,
      submitEvent,
      openPopupEvent,
      closePopupEvent,
    };
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export default YmPusher;
