import React, {
  useEffect,
  useRef,
} from 'react';

import Logo from '../Logo';

import RefService, { RefSection } from '../../../services/RefService';

import './styles.scss';

import MapPointIcon from './_media/MapPointIcon.svg';
import Gradient from './_media/corner.png';

const Header = () => {
  const decorationRef = useRef(null);

  useEffect(() => {
    RefService.getInstance().register(RefSection.HEADER_DECORATION, decorationRef);
  }, []);

  return (
    <header className='main-header'>
      <div
        ref={decorationRef}
        className='main-header__decoration'
        style={{
          backgroundImage: `url(${Gradient})`,
        }}
      />
      <Logo/>
      <img
        className='main-header__point'
        src={MapPointIcon}
        alt='Map Point'
      />
    </header>
  );
};

export default Header;
