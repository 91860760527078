import axios from 'axios';

const GeoLocationService = (() => {
  const URL = 'https://geolocation-db.com/json/';
  let instance = null;

  let currentInfo = null;

  const init = () => ({
    loadCurrentInfo: () => new Promise((resolve, reject) => {
      if (currentInfo) {
        resolve(currentInfo);
        return;
      }

      axios.get(URL)
        .then(({ data: { latitude = 0, longitude = 0 } = {} }) => {
          currentInfo = {
            latitude,
            longitude,
          };
          resolve(currentInfo);
        })
        .catch(() => {
          reject();
        });
    }),
  });

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export default GeoLocationService;
