import GA4React from 'ga-4-react';

import ReduxStoreService from '../services/ReduxStoreService';

const GaPusher = (() => {
  let instance = null;
  let isInitialized = false;
  let ReactGa4 = null;

  const init = () => {
    const initializeGa = () => {
      if (isInitialized) {
        return;
      }

      const {
        VariablesReducer: {
          variables: {
            GOOGLE_ANALYTICS_KEY,
          } = {},
        } = {},
      } = ReduxStoreService.getInstance().store.getState();

      if (GOOGLE_ANALYTICS_KEY) {
        new GA4React(
          GOOGLE_ANALYTICS_KEY,
        ).initialize()
          .then((ga4) => {
            // eslint-disable-next-line no-console
            console.log('ReactGA init');
            ReactGa4 = ga4;
            isInitialized = true;

            ga4.pageview(window.location.pathname + window.location.search);
          }, (e) => {
            // eslint-disable-next-line no-console
            console.error(e);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
          });
      }
    };

    const event = (args) => {
      if (!isInitialized) {
        return;
      }
      ReactGa4.event(args);
    };

    const standardEvent = (category, action, label) => {
      event({
        category,
        action,
        label,
      });
    };

    const clickEvent = (action, label) => {
      standardEvent('Click', action, label);
    };

    const openPopupEvent = (action, label) => {
      standardEvent('OpenPopup', action, label);
    };

    const closePopupEvent = (action, label) => {
      standardEvent('ClosePopup', action, label);
    };

    const submitEvent = (action, label) => {
      standardEvent('Submit', action, label);
    };

    return {
      initializeGa,
      event,
      standardEvent,
      clickEvent,
      openPopupEvent,
      closePopupEvent,
      submitEvent,
    };
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export default GaPusher;
